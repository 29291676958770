import { css } from '@emotion/css';
import React from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { Alert, HorizontalGroup, IconButton, useStyles2, VerticalGroup } from '@grafana/ui';
import alertDef from 'app/features/alerting/state/alertDef';
import { ChoppycastTools } from 'app/features/annotations/choppycast_annotations';

import { AnnotationsDataFrameViewDTO } from '../types';
interface AnnotationTooltipProps {
  annotation: AnnotationsDataFrameViewDTO;
  timeFormatter: (v: number) => string;
  canEdit: boolean;
  canDelete: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

export const AnnotationTooltip = ({
  annotation,
  timeFormatter,
  canEdit,
  canDelete,
  onEdit,
  onDelete,
}: AnnotationTooltipProps) => {
  const choppyCastTools = new ChoppycastTools();
  const styles = useStyles2(getStyles);
  const time = choppyCastTools.formatFromDate(annotation.time);
  const timeEnd = choppyCastTools.formatToDate(annotation.timeEnd);

  const annotationGui = {
    ignored: false,
    comment: annotation.text,
    action: '',
    activity: '',
    updated_by: annotation.email,
  };

  const tags = annotation.tags;

  tags.forEach((t) => {
    const tagName = t.split(':')[0];
    const tagValue = t.split(':')[1];

    if (tagName === 'server' || tagName === 'text') {
      return;
    }

    if (tagName === 'ignoreSale') {
      annotationGui.ignored = JSON.parse(tagValue);
    }

    if (tagName === 'selectedAction') {
      annotationGui.action = choppyCastTools.formToOptionsMapper(tagName, +tagValue);
    }
    if (tagName === 'selectedActivity') {
      annotationGui.activity = choppyCastTools.formToOptionsMapper(tagName, +tagValue);
    }
  });

  let alertText = '';
  let avatar;
  let editControls;
  let state: React.ReactNode | null = null;

  const ts = <span className={styles.time}>{Boolean(annotation.isRegion) ? `${time} - ${timeEnd}` : time}</span>;

  if (annotation.login && annotation.avatarUrl) {
    avatar = <img className={styles.avatar} alt="Annotation avatar" src={annotation.avatarUrl} />;
  }

  if (annotation.alertId !== undefined && annotation.newState) {
    const stateModel = alertDef.getStateDisplayModel(annotation.newState);
    state = (
      <div className={styles.alertState}>
        <i className={stateModel.stateClass}>{stateModel.text}</i>
      </div>
    );

    alertText = alertDef.getAlertAnnotationInfo(annotation);
  } else if (annotation.title) {
    annotationGui.comment =
      annotation.title + '<br />' + (typeof annotationGui.comment === 'string' ? annotationGui.comment : '');
  }

  if (canEdit || canDelete) {
    editControls = (
      <div className={styles.editControls}>
        {canEdit && <IconButton name={'pen'} size={'sm'} onClick={onEdit} tooltip="Edit" />}
        {canDelete && <IconButton name={'trash-alt'} size={'sm'} onClick={onDelete} tooltip="Delete" />}
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <HorizontalGroup justify={'space-between'} align={'center'} spacing={'md'}>
          <div className={styles.meta}>
            <span>
              {avatar}
              {state}
            </span>
            {ts}
          </div>
          {editControls}
        </HorizontalGroup>
      </div>

      <div className={styles.body}>
        {alertText}
        <>
          <VerticalGroup>
            <Alert hidden={!annotationGui.ignored} title={'Ignores sales data!'}>
              <VerticalGroup>
                <div>This annotation ignores sales data.</div>
              </VerticalGroup>
            </Alert>

            <p>
              <b>Comment:</b> {annotationGui.comment}
            </p>
            <p>
              <b>Marked action:</b> {annotationGui.action}
            </p>
            <p hidden={!annotationGui.activity.length}>
              <b>Marked activity:</b> {annotationGui.activity}
            </p>
            <p>
              <b>Updated by:</b> {annotationGui.updated_by}
            </p>
          </VerticalGroup>
        </>
      </div>
    </div>
  );
};

AnnotationTooltip.displayName = 'AnnotationTooltip';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    wrapper: css`
      max-width: 400px;
    `,
    commentWrapper: css`
      margin-top: 10px;
      border-top: 2px solid #2d2b34;
      height: 30vh;
      overflow-y: scroll;
      padding: 0 3px;
    `,
    header: css`
      padding: ${theme.spacing(0.5, 1)};
      border-bottom: 1px solid ${theme.colors.border.weak};
      font-size: ${theme.typography.bodySmall.fontSize};
      display: flex;
    `,
    meta: css`
      display: flex;
      justify-content: space-between;
    `,
    editControls: css`
      display: flex;
      align-items: center;
      > :last-child {
        margin-right: 0;
      }
    `,
    avatar: css`
      border-radius: ${theme.shape.radius.circle};
      width: 16px;
      height: 16px;
      margin-right: ${theme.spacing(1)};
    `,
    alertState: css`
      padding-right: ${theme.spacing(1)};
      font-weight: ${theme.typography.fontWeightMedium};
    `,
    time: css`
      color: ${theme.colors.text.secondary};
      font-weight: normal;
      display: inline-block;
      position: relative;
      top: 1px;
    `,
    body: css`
      padding: ${theme.spacing(1)};

      a {
        color: ${theme.colors.text.link};
        &:hover {
          text-decoration: underline;
        }
      }
    `,
  };
};
