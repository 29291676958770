import { isNaN } from 'lodash';

import { dateTime, DateTimeInput, isDateTime, toUtc } from '@grafana/data';

export const defaultCToolForm = {
  tags: {
    siteId: null,
    itemId: null,
    versionId: null,
    userEmail: null,
    adjustCount: null,
    selectedAction: null,
    selectedHistoricalForecastId: null,
    selectedAdjustValue: null,
    selectedAdjustValid: null,
    selectedActivity: null,
    ignoreSale: false,
    agreement: false,
  },
  text: '',
};

export type ChoppycastToolsEnablableContent =
  | 'eventActivities'
  | 'eventAdjustValues'
  | 'eventAdjustValid'
  | 'eventAdjustValues_eventAdjustValid';

export interface ChoppycastToolsInterface {
  eventActions: CTool[];
  eventActivities: CTool[];
  eventAdjustValues: CTool[];
  eventAdjustValid: CTool[];
  eventActionsCampaign: CTool[];
}

export interface CTool {
  name: string;
  id: number;
  enables?: ChoppycastToolsEnablableContent;
}

export interface CToolForm {
  tags: {
    siteId: number | null;
    itemId: number | null;
    versionId: number | null;
    userEmail: string | null;
    adjustCount: string | null;
    selectedAction: number | null;
    selectedActivity: number | null;
    ignoreSale: boolean;
    agreement: boolean;
    selectedHistoricalForecastId: string | null;
    selectedAdjustValue: number | null;
    selectedAdjustValid: number | null;
  };
  text: string;
}

export class ChoppycastTools implements ChoppycastToolsInterface {
  eventActionsCampaign: CTool[];
  eventActions: CTool[];
  eventActivities: CTool[];
  eventAdjustValues: CTool[];
  eventAdjustValid: CTool[];
  form: CToolForm;

  constructor() {
    this.form = defaultCToolForm;
    this.eventActionsCampaign = [{ name: 'Operational campaign forecast', id: 0 }];
    this.eventActions = [
      { name: 'Comment event, forecast, visitors or sale', id: 0 },
      { name: 'Mark activity', id: 1, enables: 'eventActivities' },
      { name: 'Override forecast (percent)', id: 2, enables: 'eventAdjustValues_eventAdjustValid' },
      { name: 'Override forecast (quantity)', id: 3 },
      { name: 'Use historical forecast', id: 4, enables: 'eventAdjustValues_eventAdjustValid' },
    ];
    this.eventActivities = [
      { name: 'Visitor influencing event - Negative impact', id: 6 },
      { name: 'Visitor influencing event - Positive impact (low)', id: 7 },
      { name: 'Visitor influencing event - Positive impact (medium)', id: 8 },
      { name: 'Visitor influencing event - Positive impact (high)', id: 9 },
      { name: 'External influencing event - Positive impact', id: 4 },
      { name: 'External influencing event - Negative impact', id: 5 },
      { name: 'Sales influencing activity for article - Price', id: 0 },
      { name: 'Sales influencing activity for article  - Exposure', id: 1 },
      { name: 'Sales influencing activity for article  - Price and exposure', id: 2 },
      { name: 'Sales influencing activity for article  - Other', id: 3 },
    ];
    this.eventAdjustValues = [
      { name: '-100%', id: 0 },
      { name: '-90%', id: 1 },
      { name: '-80%', id: 2 },
      { name: '-70%', id: 3 },
      { name: '-60%', id: 4 },
      { name: '-50%', id: 5 },
      { name: '-40%', id: 6 },
      { name: '-30%', id: 7 },
      { name: '-20%', id: 8 },
      { name: '-10%', id: 9 },
      { name: '+10%', id: 10 },
      { name: '+20%', id: 11 },
      { name: '+30%', id: 12 },
      { name: '+40%', id: 13 },
      { name: '+50%', id: 14 },
      { name: '+60%', id: 15 },
      { name: '+70%', id: 16 },
      { name: '+80%', id: 17 },
      { name: '+90%', id: 18 },
      { name: '+100%', id: 19 },
    ];
    this.eventAdjustValid = [
      { name: 'Lock forecast', id: 0 },
      { name: 'Adjust future forecast', id: 1 },
    ];
  }

  removeAction(id: number) {
    this.eventActions = this.eventActions.filter((f) => f.id !== id);
  }

  removeActivity(id: number) {
    this.eventActivities = this.eventActivities.filter((f) => f.id !== id);
  }

  formToOptionsMapper(formName: string, formValue: number): string {
    const map = {
      selectedAction: this.eventActions,
      selectedActivity: this.eventActivities,
      selectedAdjustValid: this.eventAdjustValid,
      selectedAdjustValue: this.eventAdjustValues,
    };

    // @ts-ignore: Unreachable code error
    return !isNaN(formValue) ? map[formName].find((f) => f.id === formValue)?.name : '';
  }

  formToOptionsMapperObject(formName: string, formValue: number): any {
    const map = {
      selectedAction: this.eventActions,
      selectedActivity: this.eventActivities,
      selectedAdjustValid: this.eventAdjustValid,
      selectedAdjustValue: this.eventAdjustValues,
    };

    return !isNaN(formValue)
      ? // @ts-ignore: Unreachable code error
        map[formName].filter((f) => f.id === formValue).map((m) => ({ label: m.name, key: m.id }))
      : null;
  }

  getIdFromSiteName(text: string) {
    const re = /\((.*)\)/i;
    let res = text.match(re);
    if (res && res[1] === 'allebutikker') {
      res = null;
    }
    return res ? res[1] : null;
  }

  getIdFromItemName(text: string) {
    const re = /\|.(.*)/i;
    const res = text.match(re);
    return res ? res[1] : null;
  }

  canExport(siteText: string, itemText: string) {
    const siteClientId = this.getIdFromSiteName(siteText);
    return siteClientId && siteClientId.length >= 4;
  }

  formatFromDate(date: DateTimeInput, timezone = 'utc'): string {
    const format = 'YYYY-MM-DD';
    date = isDateTime(date) ? date : dateTime(date);
    date.add(1, 'day');
    date.startOf('day');
    if (timezone === 'utc') {
      date.add(date.utcOffset(), 'minutes');
      return toUtc(date).format(format);
    } else {
      return dateTime(date).format(format);
    }
  }
  formatToDate(date: DateTimeInput, timezone = 'utc'): string {
    const format = 'YYYY-MM-DD';
    date = isDateTime(date) ? date : dateTime(date);
    date.startOf('day');
    if (timezone === 'utc') {
      date.add(date.utcOffset(), 'minutes');
      return toUtc(date).format(format);
    } else {
      return dateTime(date).format(format);
    }
  }
}
