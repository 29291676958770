import { locationService, getBackendSrv, getDataSourceSrv, getTemplateSrv, getAppEvents } from '@grafana/runtime';
import { ContextSrv } from 'app/core/services/context_srv';

export class FrivindCustomModifications {
  contextSrv = new ContextSrv();
  orgId: number;
  userId: number;
  userEmail: string;
  endpointsUrl = '';

  constructor() {
    this.orgId = this.contextSrv.user.orgId;
    this.userEmail = this.contextSrv.user.email;
    this.userId = this.contextSrv.user.id;

    this.setEndpointsUrl();
  }

  setEndpointsUrl(): void {
    const endpoints = getDataSourceSrv()
      .getList()
      .filter((d) => d.type === 'frivind-endpoints-datasource');

    if (endpoints.length > 0) {
      this.endpointsUrl = `/api/datasources/${endpoints[0].id}/resources`;
    }
  }

  async wait(delay: number, refreshValue: number) {
    const start = new Date().getTime();
    let end = start;
    while (end < start + delay) {
      end = new Date().getTime();
    }

    locationService.partial({ 'var-refresher': refreshValue }, true);
  }

  post(
    topic: string | undefined,
    message: Record<string, string>,
    refreshDelay: number | false,
    success: string | undefined
  ) {
    return new Promise((resolve, reject) => {
      if (this.endpointsUrl.length === 0) {
        getAppEvents().publish({
          type: 'alert-error',
          payload: ['Missing endpoints plugin'],
        });

        return resolve(false);
      }

      message['userEmail'] = this.userEmail;
      message['date'] = new Date().toISOString();

      // Send all dashboard variables when submitting to specific topic
      if (topic) {
        getTemplateSrv()
          .getVariables()
          .forEach((v) => {
            message[v.name] = (v as any).current?.value;
          });
      }

      const value = message['value'];

      // Check for variables that should be interpolated
      if (typeof value === 'string' && value.includes('__')) {
        message['value'] = getTemplateSrv().replace('${' + value + '}');
      }

      Object.keys(message).forEach((key) => {
        message[key] = getTemplateSrv().replace(`${message[key]}`);
      });

      const data = {
        topic: topic ?? 'generic-update',
        message: message,
      };

      const successMessage = success ?? 'Success';

      getBackendSrv()
        .post(this.endpointsUrl, data)
        .then(
          (success) => {
            getAppEvents().publish({ type: 'alert-success', payload: [successMessage] });

            if (refreshDelay) {
              const params = locationService.getSearch();
              const refresher = params.get('var-refresher');
              const refreshValue = refresher ? (parseInt(refresher, 10) + 1) % 2 : 1;
              this.wait(refreshDelay, refreshValue);
            }

            return resolve(true);
          },
          (error) => {
            getAppEvents().publish({ type: 'alert-error', payload: ['Something went wrong'] });

            return resolve(false);
          }
        );
    });
  }
}
