import { css, cx } from '@emotion/css';
import { isArray, isObject } from 'lodash';
import React, { HTMLAttributes, useRef, useState } from 'react';
import useAsyncFn from 'react-use/lib/useAsyncFn';
import useClickAway from 'react-use/lib/useClickAway';

import { AnnotationEventUIModel, GrafanaTheme2 } from '@grafana/data';
import {
  Button,
  Checkbox,
  Field,
  Form,
  HorizontalGroup,
  Select,
  TextArea,
  usePanelContext,
  useStyles2,
  VerticalGroup,
} from '@grafana/ui';
import { ChoppycastTools, ChoppycastToolsEnablableContent } from 'app/features/annotations/choppycast_annotations';

import { AnnotationsDataFrameViewDTO } from '../types';

interface AnnotationEditorFormProps extends HTMLAttributes<HTMLDivElement> {
  annotation: AnnotationsDataFrameViewDTO;
  timeFormatter: (v: number) => string;
  onSave: () => void;
  onDismiss: () => void;
}

export const AnnotationEditorForm = React.forwardRef<HTMLDivElement, AnnotationEditorFormProps>(
  ({ annotation, onSave, onDismiss, timeFormatter, className, ...otherProps }, ref) => {
    const styles = useStyles2(getStyles);
    const panelContext = usePanelContext();
    const clickAwayRef = useRef(null);
    const choppyCastTools = new ChoppycastTools();

    useClickAway(clickAwayRef, () => {
      onDismiss();
    });

    const [createAnnotationState, createAnnotation] = useAsyncFn(async (event: AnnotationEventUIModel) => {
      const result = await panelContext.onAnnotationCreate!(event);
      if (onSave) {
        onSave();
      }
      return result;
    });

    const [updateAnnotationState, updateAnnotation] = useAsyncFn(async (event: AnnotationEventUIModel) => {
      const result = await panelContext.onAnnotationUpdate!(event);
      if (onSave) {
        onSave();
      }
      return result;
    });

    const isUpdatingAnnotation = annotation.id !== undefined;
    const isRegionAnnotation = annotation.time !== annotation.timeEnd;
    const operation = isUpdatingAnnotation ? updateAnnotation : createAnnotation;
    const stateIndicator = isUpdatingAnnotation ? updateAnnotationState : createAnnotationState;
    const ts = isRegionAnnotation
      ? `${timeFormatter(annotation.time)} - ${timeFormatter(annotation.timeEnd)}`
      : timeFormatter(annotation.time);

    const actions = choppyCastTools.eventActions.map((m) => ({ label: m.name, key: m.id }));
    const activities = choppyCastTools.eventActivities.map((m) => ({ label: m.name, key: m.id }));
    const adjustValues = choppyCastTools.eventAdjustValues.map((m) => ({ label: m.name, key: m.id }));
    const adjustValids = choppyCastTools.eventAdjustValid.map((m) => ({ label: m.name, key: m.id }));

    // Default values
    const annotationMapped = {
      ignored: false,
      action: actions[0],
      activity: null,
      adjustValue: null,
      adjustValid: null,
    };
    // Get tags (they are string values) and map to annotationMapped
    if (annotation.tags?.length) {
      annotation.tags.forEach((t) => {
        const tagName = t.split(':')[0];
        const tagValue = t.split(':')[1];

        if (tagName === 'server' || tagName === 'text') {
          return;
        }

        if (tagName === 'ignoreSale') {
          annotationMapped.ignored = JSON.parse(tagValue);
        }

        if (tagName === 'selectedAction') {
          annotationMapped.action = choppyCastTools.formToOptionsMapperObject(tagName, +tagValue);
        }
        if (tagName === 'selectedActivity') {
          annotationMapped.activity = choppyCastTools.formToOptionsMapperObject(tagName, +tagValue);
        }
        if (tagName === 'selectedAdjustValue') {
          annotationMapped.adjustValue = choppyCastTools.formToOptionsMapperObject(tagName, +tagValue);
        }
        if (tagName === 'selectedAdjustValid') {
          annotationMapped.adjustValid = choppyCastTools.formToOptionsMapperObject(tagName, +tagValue);
        }
      });
    }

    // Set field states
    const [selectedAction, setAction] = useState(annotationMapped.action);
    const [comment, setComment] = useState(annotation.text || '');

    const [selectedActivity, setActivity] = useState(annotationMapped.activity);
    // const [adjustCount, setAdjustCount] = useState('');
    // const [selectedHistoricalForecastId, setHFI] = useState('');
    const [selectedAdjustValue, setAdjustValue] = useState(annotationMapped.adjustValue);
    const [selectedAdjustValid, setAdjustValid] = useState(annotationMapped.adjustValid);

    const [ignoreSale, setIgnoreSale] = useState(annotationMapped.ignored);
    const [agreement, setAgreement] = useState(false);

    // Kindof hacky. But the initial value of annotationMapped.action is an array
    const preSelectedEnabledFields =
      [
        // @ts-ignore: Unreachable code error
        choppyCastTools.eventActions.find((f) => f.id === annotationMapped.action[0]?.key)
          ?.enables as ChoppycastToolsEnablableContent,
      ] || [];
    const [enabledFields, setEnabled] = useState<ChoppycastToolsEnablableContent[]>(preSelectedEnabledFields);

    // Change detection
    const enableOnChange = (e: any) => {
      const newAction = e.key;

      const enabled: ChoppycastToolsEnablableContent[] = [];
      const enables = choppyCastTools.eventActions.find((f) => f.id === newAction)
        ?.enables as ChoppycastToolsEnablableContent;

      if (enables) {
        enabled.push(enables);
      }

      setEnabled(enabled);
    };

    const actionChanged = (e: any) => {
      setAction(e.key);
      enableOnChange(e);
    };

    // On submit, get field states
    const onSubmit = () => {
      const formValues = {
        selectedAction,
        selectedActivity,
        comment,
        // adjustCount,
        ignoreSale,
        agreement,
        // selectedHistoricalForecastId,
        selectedAdjustValue,
        selectedAdjustValid,
      };

      const tags: string[] = Object.entries(formValues).map(([key, value]) => {
        if (isArray(value)) {
          return `${key}:${value[0].key}`;
        } else if (isObject(value)) {
          return `${key}:${value.key}`;
        } else {
          return `${key}:${value}`;
        }
      });

      operation({
        id: annotation.id,
        tags,
        description: comment,
        from: Math.round(annotation.time!),
        to: Math.round(annotation.timeEnd!),
      });
    };

    const form = (
      <div // Annotation editor
        ref={ref}
        className={cx(styles.editor, className)}
        {...otherProps}
      >
        <div className={styles.header}>
          <HorizontalGroup justify={'space-between'} align={'center'}>
            <div className={styles.title}>{isUpdatingAnnotation ? 'Edit' : 'Add'} annotation</div>
            <div className={styles.ts}>{ts}</div>
          </HorizontalGroup>
        </div>
        <div className={styles.editorForm}>
          <Form onSubmit={onSubmit}>
            {() => {
              return (
                <>
                  <Field label={'Select option to perform'}>
                    <Select
                      options={actions}
                      value={selectedAction}
                      onChange={(e) => actionChanged(e)}
                      width={100}
                      isClearable={false}
                    />
                  </Field>

                  {/* Toggled fields */}
                  <Field label={'Select activity type'} hidden={!enabledFields.includes('eventActivities')}>
                    <Select
                      options={activities}
                      value={selectedActivity}
                      defaultValue={activities[0]}
                      onChange={(e) => setActivity(e.key)}
                      width={100}
                      isClearable={false}
                    />
                  </Field>

                  <Field
                    label={'Select adjustment factor'}
                    hidden={!enabledFields.includes('eventAdjustValues_eventAdjustValid')}
                  >
                    <Select
                      options={adjustValues}
                      value={selectedAdjustValue}
                      onChange={(e) => setAdjustValue(e.key)}
                      width={100}
                      isClearable={false}
                    />
                  </Field>

                  <Field
                    label={'Select duration'}
                    hidden={!enabledFields.includes('eventAdjustValues_eventAdjustValid')}
                  >
                    <Select
                      options={adjustValids}
                      value={selectedAdjustValid}
                      onChange={(e) => setAdjustValid(e.key)}
                      width={100}
                      isClearable={false}
                    />
                  </Field>

                  {/* Toggled fields END */}
                  <Field label={'Comment'} required={true}>
                    <TextArea
                      value={comment}
                      required={true}
                      placeholder={'Comment'}
                      cols={1}
                      disabled={false}
                      onChange={(e) => setComment((e.target as HTMLButtonElement).value)}
                    />
                  </Field>
                  <VerticalGroup>
                    <Checkbox
                      value={ignoreSale}
                      label="Ignore sales data"
                      onChange={(e) => setIgnoreSale((e.target as HTMLInputElement).checked)}
                    />
                    <Checkbox
                      value={agreement}
                      required={true}
                      label="I agree:"
                      description="All changes will have an impact on the operational forecast, and incorrect changes can have major negative consequences for forecast quality and supply of goods. Changes made to higher parts of the store and the article hierarchy will have an impact on the forecast at all lower levels. Changes are logged."
                      onChange={(e) => setAgreement((e.target as HTMLInputElement).checked)}
                    />
                  </VerticalGroup>
                  <HorizontalGroup justify={'flex-end'}>
                    <Button size={'sm'} variant="secondary" onClick={onDismiss} fill="outline">
                      Cancel
                    </Button>
                    <Button size={'sm'} type={'submit'} disabled={stateIndicator?.loading}>
                      {stateIndicator?.loading ? 'Saving' : 'Save'}
                    </Button>
                  </HorizontalGroup>
                </>
              );
            }}
          </Form>
        </div>
      </div>
    );

    return (
      <>
        <div className={styles.backdrop} />
        <div ref={clickAwayRef}>{form}</div>
      </>
    );
  }
);

AnnotationEditorForm.displayName = 'AnnotationEditorForm';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    backdrop: css`
      label: backdrop;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      overflow: hidden;
      z-index: ${theme.zIndex.navbarFixed};
    `,
    editorContainer: css`
      position: absolute;
      top: calc(100% + 10px);
      transform: translate3d(-50%, 0, 0);
    `,
    editor: css`
      background: ${theme.colors.background.primary};
      box-shadow: ${theme.shadows.z3};
      z-index: ${theme.zIndex.dropdown};
      border: 1px solid ${theme.colors.border.weak};
      border-radius: ${theme.shape.borderRadius()};
      width: 460px;
    `,
    editorForm: css`
      padding: ${theme.spacing(1)};
    `,
    header: css`
      border-bottom: 1px solid ${theme.colors.border.weak};
      padding: ${theme.spacing(1.5, 1)};
    `,
    title: css`
      font-weight: ${theme.typography.fontWeightMedium};
    `,
    ts: css`
      font-size: ${theme.typography.bodySmall.fontSize};
      color: ${theme.colors.text.secondary};
    `,
  };
};
