import React, { useState, useMemo } from 'react';
import Select from 'react-select';
import { Cell } from 'react-table';

import { TimeRange, DataFrame, SelectableValue } from '@grafana/data';

import { IconName } from '../../types/icon';
import { Button } from '../Button/Button';
import { Switch } from '../Switch/Switch';

import { TableStyles } from './styles';
import { GrafanaTableColumn, TableFilterActionCallback } from './types';

export interface ClickableCell {
  isClickable: boolean;
  topic?: string | undefined;
  rowNo?: number;
  type?: 'string' | 'number' | 'button' | 'select' | 'switch';
  queryRefId?: string | undefined;
  refreshDelay: number | false;
  removeRowOnSuccess?: boolean | undefined;
  successMessage?: string | undefined;
  tableToUpdate?: string;
  updateField?: string;
  options?: ButtonProps[] | SelectableValue[];
  variables?: Record<string, string> | undefined;
}

export interface ButtonProps {
  value: string;
  icon: IconName;
  tooltip: string;
  tableToUpdate?: string | undefined;
  updateField?: string | undefined;
  topic?: string | undefined;
}

export interface Props {
  cell: Cell;
  tableStyles: TableStyles;
  onCellFilterAdded?: TableFilterActionCallback;
  columnIndex: number;
  columnCount: number;
  timeRange?: TimeRange;
  userProps?: object;
  frame: DataFrame;
  queries?: { [id: string]: SelectableValue[] };
  clickableCell?: ClickableCell;
  onCellChanged?: (
    clickableCell: ClickableCell,
    valueColumnName: string,
    value: string | number | boolean,
    topic: string | undefined,
    tableToUpdate: string | undefined,
    updateField: string | undefined
  ) => void;
}

export const TableCell = ({
  cell,
  tableStyles,
  onCellFilterAdded,
  timeRange,
  userProps,
  frame,
  clickableCell,
  onCellChanged,
  queries,
}: Props) => {
  const cellProps = cell.getCellProps();
  const field = (cell.column as unknown as GrafanaTableColumn).field;

  const [isInputVisible, setIsInputVisible] = useState(['button', 'switch'].includes(clickableCell?.type ?? ''));
  const [inputValue, setInputValue] = useState(cell.value);

  const options = useMemo(() => {
    if (!clickableCell) {
      return [];
    }

    const queryRefId = clickableCell.queryRefId;

    if (!queries || !queryRefId) {
      return clickableCell.options;
    }

    return queries[queryRefId];
  }, [queries, clickableCell]);

  if (!field?.display) {
    return null;
  }

  if (cellProps.style) {
    cellProps.style.minWidth = cellProps.style.width;
    cellProps.style.justifyContent = (cell.column as any).justifyContent;
  }

  let innerWidth = ((cell.column.width as number) ?? 24) - tableStyles.cellPadding * 2;

  const handleCellClick = (event: React.MouseEvent<HTMLElement>) => {
    if (clickableCell && clickableCell.isClickable && !isInputVisible) {
      setIsInputVisible(true);
    }
  };

  const handleInputChange = (event: any) => {
    if (clickableCell?.type === 'button') {
      setInputValue(event.value);
      setIsInputVisible(false);
      valueChanged(event.value);
    } else {
      setInputValue(event.target.value);
    }
  };

  const handleOnSelect = (selection: SelectableValue | null) => {
    if (clickableCell?.type === 'select' && selection) {
      setInputValue(selection.value);
      setIsInputVisible(false);
      valueChanged(selection.value, selection.label);
    }
  };

  const handleInputKeyPress = (event: { key: string }) => {
    if (event.key === 'Enter') {
      setIsInputVisible(false);
      const value = clickableCell?.type === 'number' ? parseFloat(inputValue) : inputValue;
      valueChanged(value);
    }
  };

  const handleOnSwitch = () => {
    const value = !inputValue;
    setInputValue(value);
    valueChanged(value);
  };

  const handleOnBlur = () => {
    setIsInputVisible(false);
  };

  const valueChanged = (
    value: string | number | boolean,
    displayValue?: string | null,
    topic?: string | undefined,
    tableToUpdate?: string | undefined,
    updateField?: string | undefined
  ) => {
    if (onCellChanged && clickableCell) {
      onCellChanged(clickableCell, field.name, value, topic, tableToUpdate, updateField);
      cell.value = displayValue ?? value;
    }
  };

  const cellRender = cell.render('Cell', {
    field,
    tableStyles,
    onCellFilterAdded,
    cellProps,
    innerWidth,
    timeRange,
    userProps,
    frame,
  });

  if (cellProps.style) {
    cellProps.style.height = '100%';
  }

  const buttonRowStyle = {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: 2,
    marginLeft: 2,
  };

  const inputField = (
    <div style={cellProps.style}>
      {clickableCell?.type === 'button' ? (
        <div style={buttonRowStyle}>
          {clickableCell.options?.map((element, index) => {
            const e = element as ButtonProps;
            return (
              <Button
                key={index}
                size="md"
                icon={e.icon}
                tooltip={e.tooltip}
                onClick={() => {
                  valueChanged(e.value, undefined, e.topic, e.tableToUpdate, e.updateField);
                }}
                variant="secondary"
              ></Button>
            );
          })}
        </div>
      ) : clickableCell?.type === 'select' ? (
        <Select
          options={options}
          onChange={handleOnSelect}
          onBlur={handleOnBlur}
          value={inputValue}
          autoFocus={true}
          openMenuOnFocus={true}
          // style={{ textAlign: 'right', width: '100%', height: '100%' }}
        />
      ) : clickableCell?.type === 'switch' ? (
        <div style={{ display: 'flex', height: '100%', alignItems: 'center', marginLeft: 2 }}>
          <Switch value={inputValue} onChange={handleOnSwitch} />
        </div>
      ) : (
        // <Select
        //   id={'select-' + clickableCell.updateField + '-' + clickableCell.rowNo}
        //   options={clickableCell?.options}
        //   value={inputValue}
        //   onChange={handleSelectChange}
        //   // onCloseMenu={handleOnBlur}
        //   isOpen={isInputVisible}
        //   // openMenuOnFocus={true}
        //   // autoFocus={true}
        //   style={{ textAlign: 'right', width: '100%', height: '100%' }}
        // />
        <input
          type={clickableCell?.type}
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          onBlur={handleOnBlur}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={true}
          style={{ textAlign: 'right', width: '100%', height: '100%' }}
        />
      )}
    </div>
  );

  return (
    <>
      <div role="presentation" onClick={handleCellClick}>
        {isInputVisible ? inputField : cellRender}
      </div>
    </>
  );
};
